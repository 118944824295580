.reserva {
    margin: 80px auto;
    max-width: 90%;
    display: flex;
    align-content: center;
    justify-content: space-between;
}
.reserva-col {
    flex-basis: 48%;
}
.reserva-col h3 {
    font-weight: 600;
    font-size: 25px;
    color: #591902;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.reserva-col h3 img {
    width: 35px;
    margin-left: 10px;
}
.reserva-col p {
    max-width: 450px;
    list-style: 0.3;
}
.reserva-col ul li {
    display: flex;
    align-items: center;
    margin: 20px 0;
}
.reserva-col ul li img {
    width: 25px;
    margin-right: 10px;
}
.btn {
    background: #591902;
    color: white;
    padding: 14px 25px;
    font-size: 16px;
    border-radius: 30px;
    cursor: pointer;
    border: 0;
    outline: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.btn img {
    width: 20px;
    margin-left: 10px;
}
.reserva-col form input, .reserva-col form textarea {
display: block;
width: 100%;
background: #EBECFE;
padding: 15px;
border: 0;
outline: 0;
margin-top: 5px;
margin-bottom: 15px;
resize: none;
}
.reserva-col span {
    display: block;
    margin: 20px 0;
}
/* ---- Media Query ---- */
@media (max-width: 700px) {
   .reserva{ display: block;
    }
    .reserva-col {
        padding: 20px;
    }
}