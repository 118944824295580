body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #D9C091;
}


.btn {
  background: #D9C091;
  color: #212121;
  padding: 14px 25px;
  font-size: 16px;
  border-radius: 30px;
  cursor: pointer;
  border: 0;
  outline: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.btn img {
  width: 20px;
  margin-left: 10px;
  /*Margen con el nombre del botón*/
}

/* ---- Media Query ---- */

@media (max-width: 1200px) {
  .container{
    padding-left: 5%;
    padding-right: 5%;
  }
  .btn{
    padding: 14px 22px;
  }
}