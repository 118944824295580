.entrance{
width: 100%;
min-height: 100vh;
background: url('/public/kayleigh-harrington-yhn4okt6ci0-unsplash\ recortada.jpg');
background-position: center;
background-size: cover;
display: flex;
align-items: center;
justify-content: center;
}
.entrance-text{
    color: #FFF;
    text-align: center;
    max-width: 800px;
}
.entrance-text h1 {
    font-size: 60px;
}
.entrance-text p {
    max-width: 700px;
    margin: 10px auto 20px;
    line-height: 1.4;
    background-color: #591902;
    border-radius: 15px;
}
/* ---- Media Query ---- */
@media (max-width: 850px){
    .entrance-text h1 {
        font-size: 40px;
    }
}
@media (max-width: 650px) {
    .entrance-text h1 {
        font-size: 30px;
        max-width: 400px;
        margin: auto;
    }
    .entrance-text p{
        font-size: 14px;
        margin: 15px auto 30px;
    }
}