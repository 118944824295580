.Header {
    position: fixed;

}
.navbar {
    position: fixed;
    /* Agrega esta línea para fijar la barra de navegación */
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    /* Establece el contenedor como flexbox */
    justify-content: center;
    /* Centra los elementos horizontalmente */
    align-items: center;
    height: 150px;
    padding-top: 0px;
    /* Añadido para espacio superior */
    /* Ajusta la altura según sea necesario */
    background-color: #F28705;
}

.navbar-logo-container {
    flex: 1;
    /* El logo toma todo el espacio disponible */
    text-align: center;
    /* Centra el logo horizontalmente */
    padding-bottom: 20px;
    /* El logo recibe espacio inferior */
}

.navbar ul {
    display: flex;
    /* Establece la lista como flexbox */
    flex: 1;
    /* La lista toma todo el espacio disponible */
    justify-content: space-between;
    /* Distribuye los elementos a lo largo del contenedor */
    list-style: none;
    /* Quita los estilos de la lista */
    padding: 0;
    /* Quita el padding predeterminado de la lista */
    margin: 0;
    /* Añadido para evitar márgenes predeterminados */

}

.navbar li {
    margin: 10px 20px;
    /* Ajusta los márgenes entre los elementos de la lista */
    display: inline-block;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 21px;
    font-style: italic;
    text-align: center;
    cursor: pointer;
}

.logo-img {
    width: 75px;
    /* Establece el ancho del logo */
    height: auto;
    /* Mantiene la proporción original */
    position: fixed;
    /*Ajustado con el texto*/
    display: inline;
    vertical-align: top;

}
.menu-icon {
    display: none;
}
/* ---- Media Query ---- */

@media (max-width: 1000px) {
    .logo-img{
    width: 50px;
    }
    nav ul li{
        margin: 10px 14px;
    }
}

@media (max-width: 840px) {
    .navbar {
            flex-direction: row;
            justify-content: space-between;
            padding: 10px;
        }
    nav ul {
        position: fixed;
        right: 0;
        top: 0;
        bottom: 0;
        background: #F28705;
        z-index: 1;
        width: 200px;
        height: 100%;
        padding-top: 70px;
        transition: 0.5s;
        flex-direction: column;
            /* Asegura que los elementos sean verticales */
        overflow-y: auto;
        font-size: 16px;
    }
    nav ul li {
        display: block;
        margin: 10px 0px;
        
    }
    .menu-icon {
        display: block;
        width: 30px;
        cursor: pointer;
        position: fixed;
        top: 20px;
        right: 20px;
        z-index: 2;
    }
    .hide-mobile-menu {
        right: -200px;
    }
}