.menu {
    width: 100%;
    min-height: 100vh;
    background: url('/public/chinh-le-duc-u2plW-ShfCY-unsplash.jpg');
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}
.menu-text {
    text-align: center;
    font-size: 24px;
    color: #FFF;
}
.platos {
    text-align: center;
    font-size: 18px;
    color: black;
    background-color: #FFF;
    border-radius: 25px;
}
.menu-llevar {
    text-align: center;
    font-size: 24px;
    color: black;
}
/* ---- Media Query ---- */
@media (max-width: 650px){
    .menu-text {
        font-size: 18px;
    }
    .platos {
        font-size: 12px;
    }
    .menu-llevar{
        font-size: 18px;
    }
}