.specialities {
    background-color: #D9C091;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
}

.specialitie {
    margin: 80px auto;
    width:  90%;
    padding-left: 5%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    flex-basis: 31%;
}

.specialitie img {
    width: 300px;
    border-radius: 10px;
    display: block;
}

.caption {
    border-radius: 10px;
    position: absolute;
    color: #fff;
    background-color: rgba(231, 58, 58, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    opacity: 0;
    padding-top: 70%;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transition: 0.4s;
}
.specialitie:hover .caption {
    opacity: 1;
    padding-top: 0;
    overflow: hidden;
}
/* ---- Media Query ---- */
@media (max-width: 850px) {
    .specialitie img {
        width: 160px;
        margin-left: 20px;
        margin-right: 20px;
    }
    .specialitie h1 {
        font-size: 18px;
    }
    .specialitie p {
        font-size: 12px;
    }
}

@media (max-width: 650px) {
    .specialities{
        flex-direction: column;
    }
    .specialitie{
        flex-basis: 100%;
        margin: 20px;
    }
    .specialitie img {
        width: 145px;
    }
    .specialitie h1 {
        font-size: 14px;
    }
    .specialitie p {
        font-size: 8px;
    }
}