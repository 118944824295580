.groupmenu {
margin: 80px auto;
padding: 0 80px;
position: relative;
}

.next-btn, .back-btn {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    padding: 15px;
    width: 50px;
    border-radius: 50%;
    cursor: pointer;
    background: #591902;
}

.back-btn {
    right: auto;
    left: 0;
}
.slider {
    overflow: hidden;
}
.slider ul {
    display: flex;
    width: 200%;
    overflow-x: hidden;
    transition: 0.5s;
}
.slider ul li {
    list-style: none;
    width: 50%;
    padding: 20px;
}

.slide {
    box-shadow: 0 0 20px rgba(0,0,0,0.05);
    padding: 40px;
    border-radius: 10px;
    color: #591902;
    line-height: 1.4;
}
.slide img {
    width: 65px;
    border-radius: 50%;
    margin-right: 10px;
    border: 4px solid #F28705
}
.menug-info {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-size: 15px;
}
.btn-reserva {
    background: #F28705;
    color: #212121;
    padding: 14px 25px;
    font-size: 14px;
    border-radius: 30px;
    cursor: pointer;
    border: 0;
    outline: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.btn-reserva img {
    width: 12px;
    margin-left: 10px;
}
/* ---- Media Query ---- */
@media (max-width: 900px) {
    .groupmenu {
        padding: 0 40px;
    }
    .back-btn, .next-btn {
        padding: 10px;
        width: 35px;
    }
    .groupmenu ul li {
        padding: 10px;
    }
    .slide {
        padding: 10px;
    }
    .slide img {
        width: 50px;
        border: 3px solid #F28705;
    }
}
@media (max-width: 640px) {
    .menug-info {
        display: block;
        margin-bottom: 10px;
        font-size: 12px;
    }
    .menug-info h3 {
        font-size: 16px;
    }
    .slider p{
        font-size: 8px;
    }
}